import React from "react"
import LayoutComponent from "../../components/Layout.js"
import Straaleterapi from "../../components/Straaleterapi.js"
import Knap from "../../components/Knap.js"


const StraaleterapiPage = () => (
    <LayoutComponent>
        <h1>Stråleterapi</h1>
        <p>Hos Easymed har vi en lille, men nøje udvalgt, gruppe af leverandører, der kan leverere tilbehør til dosimetri, dosimetre, stråleanalyse, kvalitetskontrol (QA) og andre stråleterapeutiske instrumenter.</p>
        <h1>Vores leverandører</h1>
        <Straaleterapi />
        <Knap />
    </LayoutComponent>
)
export default StraaleterapiPage